import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../toastAlert.js/toastalert";
import { getToken } from "../utils/tokenUtils";
import { apiBaseUrl } from "../globals/baseUrl";
import { useParams } from "react-router-dom";
import moment from "moment-timezone"; // Install moment-timezone with npm or yarn
import BlueButton from "./Button";
import { Button } from "antd";
import { BiRightArrow, BiRightArrowAlt } from "react-icons/bi";
import { Tabs, Table, Typography } from "antd";

const { TabPane } = Tabs;
const { Title, Text } = Typography;

type Props = {};

interface Report {
    uid: string;
    from: string;
    to: string;
    type: string;
    sms_count: string | number;
    cost: string;
    status: string;
    date: string;
}

interface SubscriptionLogs{
   createdAt: string;
   message: string;
}

interface TransactionLogs{
   createdAt: string;
   message: string;
   amount: string;
   status: string;
}

interface Customer {
  id: number;
  uid: string;
  api_token: string;
  first_name: string;
  last_name: string;
  email: string;
  email_verified_at: string | null;
  status: boolean;
  image: string | null;
  sms_unit: string;
  is_admin: boolean;
  is_customer: boolean;
  active_portal: string;
  two_factor: boolean;
  two_factor_code: string | null;
  two_factor_expires_at: string | null;
  two_factor_backup_code: string | null;
  locale: string;
  timezone: string;
  last_access_at: string | null;
  provider: string | null;
  provider_id: string | null;
  created_by: number;
  created_at: string;
  updated_at: string;
  api_sending_server: string | null;
  is_reseller: boolean;
}

const CustomerDetails = (props: Props) => {
    const token = getToken();
    const { uid } = useParams();
    const [customer, setCustomer] = useState<Customer | null>(null);
    const [subscriptionLogs, setSubscriptionLogs] = useState<SubscriptionLogs[] | null>(null);
    const [transactionLogs, setTransactionLogs] = useState<TransactionLogs[] | null>(null);
    const [report, setReport] = useState<Report[] | null>(null);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchCustomer = async () => {
        const config = {
          url: `${apiBaseUrl}/users/customers/${uid}/show`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
  
        try {
          const response = await axios.request(config);
          if (response.status >= 200 && response.status < 300) {
            showSuccessfulToastAlert(response.data.message);
            setCustomer(response.data.customer);
          }
        } catch (error: any) {
          showFailedToastAlert(error.message);
        } finally {
          setLoading(false);
        }
      };
      
      fetchCustomer();
    }, [token, uid]);
  
    useEffect(() => {
      if (customer) {
        const fetchReport = async () => {
          const config = {
            url: `${apiBaseUrl}/users/customers/${uid}/reports`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            params: {
                user_uid: uid, // Pass user_uid as a query parameter
                recipient: customer.first_name,
            },      
          };
  
          try {
            const response = await axios.request(config);
            if (response.status >= 200 && response.status < 300) {
              setReport(response.data.data.reports);
            }
          } catch (error: any) {
            showFailedToastAlert(error.message);
          }
    
        };
  
        fetchReport();
      }
    }, [customer, token, uid]);


    // Function to format the date
    const formatDate = (date) => {
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };
        return new Date(date).toLocaleString('en-US', options);
    };
    
    

    // Function to extract the message from the 'data' field
    const formatMessage = (data) => {
        const parsedData = JSON.parse(data);
        return `Assigned to ${parsedData.plan} plan by Admin`;
    };
    


    useEffect(() => {
      if (customer) {
        const fetchLogs = async () => {
          const config = {
            url: `${apiBaseUrl}/users/customers/${uid}/logs`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            }     
          };
  
          try {
            const response = await axios.request(config);
            if (response.status >= 200 && response.status < 300) {
                let rawSubscriptionLogs = response.data.subscriptionLogs;
                let rawTransactionLogs = response.data.transactionLogs;

                const formattedSubscriptionData = rawSubscriptionLogs.map(item => ({
                    createdAt: formatDate(item.created_at),
                    message: formatMessage(item.data),
                }));
                
                setSubscriptionLogs(formattedSubscriptionData)
                const formattedTransactionsData = rawTransactionLogs.map(item => ({
                    createdAt: formatDate(item.created_at),
                    message: item.title,
                    status: item.status,
                    amount: item.amount,
                }));

                setTransactionLogs(formattedTransactionsData)
                

            
            }
          } catch (error: any) {
            showFailedToastAlert(error.message);
          }
    
        };
  
        fetchLogs();
      }
    }, [customer, token, uid]);
  
    if (loading) return <div>Loading...</div>;
  

  const formatDateToKathmandu = (date: string) => {
    return moment(date).tz("Asia/Kathmandu").format("YYYY-MM-DD HH:mm:ss");
  };

  const excludedFields = ["created_by"];

  let subscriptionLogsColumns = [
    {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
      },
      {
        title: "Message",
        dataIndex: "message",
        key: "message",
      },
  ];

  let transactionLogsColumns = [
    {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
      },
      {
        title: "Message",
        dataIndex: "message",
        key: "message",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
      },
  ]

  const reportColumns = [
    {
      title: "UID",
      dataIndex: "uid",
      key: "uid",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Type",
      dataIndex: "sms_type",
      key: "sms_type",
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "SMS Count",
      dataIndex: "sms_count",
      key: "sms_count",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  return (
    <div style={{ padding: 20,  margin: "auto" }}>
      <Tabs defaultActiveKey="1">
        {/* Account Tab */}
        <TabPane tab="Account" key="1">
          <div>
            <Title level={4}>Account Details</Title>
            <div className="mt-5">
              <div className="grid grid-cols-2 gap-x-[33px] gap-y-[30px]">
                <div>
                  <p className="text-[#5F6368] text-[13px] leading-[18.2px] font-normal ">
                    Email
                  </p>
                  <input
                    type="email"
                    name="email"
                    readOnly={true}
                    value={customer?.email}
                    className="border hover:border-[#D9D9D9] bg-[#EEEEEE] pl-[14px] outline-none h-[46px] w-full mt-[10px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
                  />
                </div>
                <div>
                  <p className="text-[#5F6368] text-[13px] leading-[18.2px] font-normal ">
                    Created At
                  </p>
                  <input
                    type="text"
                    name="timezone"
                    readOnly={true}
                    value={customer?.created_at}
                    className="border hover:border-[#D9D9D9] bg-[#EEEEEE] pl-[14px] outline-none h-[46px] w-full mt-[10px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
                  />
                </div>
                <div className="grid grid-cols-2 gap-[30px]">
                  <div>
                    <p className="text-[#5F6368] text-[13px] leading-[18.2px] font-normal ">
                      First name
                    </p>
                    <input
                      type="text"
                      name="first_name"
                      readOnly={true}
                      value={customer?.first_name}
                      className="border hover:border-[#D9D9D9] bg-[#EEEEEE] pl-[14px] outline-none h-[46px] w-full mt-[10px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
                    />
                  </div>
                  <div>
                    <p className="text-[#5F6368] text-[13px] leading-[18.2px] font-normal ">
                      Last name
                    </p>
                    <input
                      type="text"
                      name="last_name"
                      readOnly={true}
                      value={customer?.last_name}
                      className="border hover:border-[#D9D9D9] bg-[#EEEEEE] pl-[14px] outline-none h-[46px] w-full mt-[10px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
                    />
                  </div>
                </div>
                <div>
                  <p className="text-[#5F6368] text-[13px] leading-[18.2px] font-normal ">
                    Status
                  </p>
                  <input
                      type="text"
                      readOnly={true}
                      name="last_name"
                      value={customer?.status ? "Active" : "In Active"}
                      className="border hover:border-[#D9D9D9] bg-[#EEEEEE] pl-[14px] outline-none h-[46px] w-full mt-[10px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
                    />
                </div>
              </div>
            
            </div>
          </div>
        </TabPane>


        {/* SMS Report */}
        <TabPane tab="SMS Report" key="2">
          <div >
            <Title level={4}>SMS Sending Report for {customer?.first_name} {customer?.last_name}</Title>
            <Table
              dataSource={report ?? []}
              columns={reportColumns}
              pagination={false}
            />
          </div>
        </TabPane>

        {/* Subscription Logs */}
        <TabPane tab="Subscription Logs" key="3">
          <div >
            <Title level={4}>Subscription Logs</Title>  
            <Table
              dataSource={subscriptionLogs ?? []}
              columns={subscriptionLogsColumns}
              pagination={false}
            />      
          </div>
        </TabPane>

        {/* Transaction Logs */}
        <TabPane tab="Transaction Logs" key="4">
          <div >
            <Title level={4}>Transaction Logs</Title>  
            <Table
              dataSource={transactionLogs ?? []}
              columns={transactionLogsColumns}
              pagination={false}
            />      
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default CustomerDetails;
